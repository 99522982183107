import "./styles.css";

const sanityClient = require("@sanity/client");
const client = sanityClient({
    projectId: "shpe7kia",
    dataset: "production",
    apiVersion: "2021-03-25",
    useCdn: true
});
const query = '*[_type == "alcool"] | order(name) { name, value }';

client.fetch(query).then((data) => {
    data.forEach(el => {

        const newItem = document.createElement('h3');
        let newItemName = document.createElement('span');
        newItemName.innerHTML = el.name;
        let newItemDots = document.createElement('span');
        newItemDots.classList.add("dots");
        let newItemValue = document.createElement('span');
        newItemValue.innerHTML = el.value;
        newItem.appendChild(newItemName);
        newItem.appendChild(newItemDots);
        newItem.appendChild(newItemValue);

        document.getElementsByClassName("list-container")[0].appendChild(newItem);

    });
});

document.getElementById("app").innerHTML = `
<div class="container">
  <h1 class="text-center">Chez Galaad</h1>
  <h2 class="text-center">Les alcools</h1>
  <div class="list-container">
  
  </div>
</div>
`;